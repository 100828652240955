<template>
<v-app-bar app color="primary" dark>
    <v-toolbar-title>G.M.Flor Tracciabilità</v-toolbar-title>
    <v-spacer/>
    <v-btn icon to="/stampa_commercial">
        <v-icon>mdi-printer</v-icon>
    </v-btn>
    <v-btn icon to="/stampa">
        <v-icon>mdi-printer</v-icon>
    </v-btn>
    <v-btn icon to="/prodotti">
        <v-icon>mdi-spa</v-icon>
    </v-btn>
    <v-btn icon to="/">
        <v-icon>mdi-barcode</v-icon>
    </v-btn>
    <v-btn icon to="/fornitori">
        <v-icon>mdi-account-cash-outline</v-icon>
    </v-btn>
    <v-btn icon to="/torbe">
        <v-icon>mdi-sprout</v-icon>
    </v-btn>
    <v-btn icon to="/movimenti">
        <v-icon>mdi-package-up</v-icon>
    </v-btn>
</v-app-bar>
</template>

<script>
export default {

}
</script>
