<template>
    <LottiMain/>
</template>

<script>
import LottiMain from '@/components/lotti/lotti_main'
export default {
    components:{
        LottiMain
    }
    
}
</script>