import axios from 'axios'

export default {
    state: {
        token: ''
    },
    getters: {
        get_token: state => {
            return state.token
        },
        get_auth: state =>{
            return {
                'username': state.token,
                'password': ''
            }
        }
    },
    mutations: {
        set_token(state, token) {
            state.token = token
        }
    },
    actions: {
        request_token(context, payload) {
            return new Promise((resolve, reject) => {
                let url = "https://listino.gmflor.com/api/token"
                axios.get(url, { 'auth': { 'username': payload.username, 'password': payload.password } })
                    .then((res) => {
                        localStorage.setItem("token", res.data.token)
                        context.commit("set_token", res.data.token)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }
}