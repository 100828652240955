<template>
  <Home></Home>

</template>

<script>
import Home from '@/components/home/home.vue'
export default {
  components: {
    Home
  }
}
</script>
