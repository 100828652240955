<template>
<v-row align="center">
    <v-col cols="1">
        <v-avatar>
            <v-img :src="fornitore.logo_src"/>
        </v-avatar>
    </v-col>
    <v-col cols="1">
        {{lotto.codice}}
    </v-col>
    <v-col cols="2">
        {{data}}
    </v-col>
    <v-col cols="1">
        {{settimana}}
    </v-col>
    <v-col cols="4">
        {{fornitore.nome}}
    </v-col>
    <v-col cols="2">
        {{lotto.documento}}
    </v-col>
    <v-col cols="1">
        <v-dialog v-model="dialog" width="400">
            <template v-slot:activator="{on}">
                <v-btn icon v-on="on">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="red--text">
                    Sei sicuro ?
                </v-card-title>
                <v-card-actions>
                    <v-spacer />
                    <v-btn outlined color="red" @click="elimina">
                        Elimina
                    </v-btn>
                    <v-btn text color="grey" @click="dialog = false">
                        Anulla
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-col>
</v-row>
</template>

<script>
import moment from 'moment'
export default {
    props: {
        lotto: Object
    },
    data() {
        return {
            dialog: false,
            loading: false
        }
    },
    computed: {
        settimana() {
            return moment.unix(this.lotto.data).format("W")
        },
        data() {
            moment.locale('it_IT')
            return moment.unix(this.lotto.data).format("dddd DD MMMM")
        },
        fornitore() {
            return this.$store.getters.get_fornitore_by_cod(this.lotto.produttore)
        }
    },
    methods: {
        elimina() {
            this.loading = true
            this.$store.dispatch("delete_lotto", this.lotto.id)
                .catch((err) => {
                    console.log(err)
                    alert("errore")
                })
                .finally(() => {
                    this.dialog = false
                    this.loading = false
                })
        }
    }
}
</script>
