import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Lotti from '../views/Lotti.vue'
import Prodotti from '../views/Prodotti.vue'
import Fornitori from '../views/Fornitori.vue'
import Torbe from '../views/Torbe.vue'
import Movimenti from '../views/Movimenti.vue'
import Login from '../views/Login.vue'
import Stampa from '../views/Stampa.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/a',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Lotti',
    component: Lotti
  },
  {
    path: '/prodotti',
    name: 'Prodotti',
    component: Prodotti
  },
  {
    path: '/fornitori',
    name: 'Fornitori',
    component: Fornitori
  },
  {
    path: '/torbe',
    name: 'Torbe',
    component: Torbe
  },
  {
    path: '/movimenti',
    name: 'Movimenti',
    component: Movimenti
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/stampa',
    name: 'Stampa',
    component: Stampa
  },
  {
    path: '/stampa_commercial',
    name: 'Stampa',
    component: () => import("../views/Stampa_Commercial.vue")
  },
]

const router = new VueRouter({
  routes
})

export default router
