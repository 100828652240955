<template>
<v-row justify="center">
    <v-col cols="8">
        <v-card :loading="loading">
            <v-card-title>
                Movimenti
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <v-text-field v-model="search_codice" outlined placeholder="Cerca Codice ..." append-outer-icon="mdi-magnify" @click:append-outer="search()"></v-text-field>
                    </v-col>
                </v-row>
                <template v-for="movimento in movimenti">
                    <MovimentoElement :movimento="movimento" :key="movimento.iva" />
                </template>
            </v-card-text>
        </v-card>
    </v-col>

</v-row>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import MovimentoElement from './movimento_element'
import axios from 'axios'
export default {
    components: {
        MovimentoElement
    },
    data() {
        return {
            search_codice: '',
            movimenti: [],
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            prododotti: 'get_prodotti'
        }),
    },
    methods: {
        search() {
            this.loading = true
            let cod = this.search_codice.substring(0, 5)
            let lot = this.search_codice.substring(5)
            let url = this.$store.getters.get_api_root + '/lotto/' + cod + '/' + lot
            axios.get(url)
                .then(res => {
                    this.movimenti = res.data.lista
                })
                .finally(() => {
                    this.loading = false

                })
        }
    }

}
</script>
