<template>
<v-row justify="center">
    <v-col cols="8">
        <v-card>
            <v-card-title>
                Torbe
                <v-spacer/>
                <NuovoTorba/>
            </v-card-title>
            <v-card-text>
                 <v-row>
                    <v-col cols="4">
                    <v-text-field v-model="search_text" outlined placeholder="Cerca Torba ..."></v-text-field>
                        
                    </v-col>
                </v-row>
                <v-row class="mb-2">
                    <v-col cols="1">

                    </v-col>
                    <v-col cols="1">
                        Codice
                    </v-col>
                    <v-col cols="3">
                        Produttore
                    </v-col>
                    <v-col cols="4">
                        Nome
                    </v-col>
                </v-row>
                <v-divider/>
                <template v-for="torba in filtered">
                    <TorbaElement :torba="torba" :key="torba.codice"/>
                </template>
            </v-card-text>
        </v-card>
    </v-col>

</v-row>
</template>

<script>
import {mapGetters} from 'vuex'
import TorbaElement from './torba_element'
import NuovoTorba from './nuovo_torba'
export default {
    components:{
        TorbaElement,
        NuovoTorba
    },
    data(){
        return{
            search_text: ''
        }
    },
    computed:{
        ...mapGetters({
            torbe: 'get_torbe'
        }),
        filtered() {
            if (this.search_text.length > 2) {
                let regex = new RegExp(this.search_text, 'i')
                return this.torbe.filter(torba => {
                    return regex.test(torba.nome)
                }).sort((a, b) => {
                    return a - b
                })
            } else {
                return this.torbe
            }
        }
    }

}
</script>