<template>
    <FornitoriMain/>
</template>

<script>
import FornitoriMain from '@/components/fornitori/fornitori_main'
export default {
    components:{
        FornitoriMain
    }
}
</script>