<template>
<v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{on}">
        <v-btn icon v-on="on">
            <v-icon>mdi-content-copy</v-icon>
        </v-btn>
    </template>
    <v-card>
        <v-card-title>
            Copia varietà
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-autocomplete outlined v-model="p_from" item-value="id" item-text="nome" :items="prodotti"></v-autocomplete>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn outlined @click="submit()" :loading="loading" color="green">
                Invia
            </v-btn>
            <v-btn text color="grey" @click="dialog = false">
                annulla
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    props: {
        prodotto: Object
    },
    data() {
        return {
            p_from: '',
            dialog: false,
            loading: false
        }
    },
    computed: {
        prodotti() {
            return this.$store.getters.get_prodotti
        }
    },
    methods: {
        submit() {
            this.loading = true
            this.$store.dispatch("copy_varieta", {
                    'to': this.prodotto.id,
                    'from': this.p_from
                })
                .catch(err => {
                    alert("Error: " + err)
                })
                .finally(() => {
                    this.loading = false
                    this.dialog = false
                })
        }
    }
}
</script>
