import Vue from 'vue'
import Vuex from 'vuex'

import Prodotti from'./modules/prodotti'
import Fornitori from './modules/fornitori'
import Torbe from './modules/torbe'
import Lotti from './modules/lotti'
import Movimenti from './modules/movimenti'
import Api from './modules/api'
import User from './modules/user'
import Clienti from './modules/clienti'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Prodotti,
    Fornitori,
    Torbe,
    Lotti,
    Movimenti,
    Api,
    User,
    Clienti
  }
})
