<template>
    <MovimentiMain/>
</template>

<script>
import MovimentiMain from '@/components/movimenti/movimenti_main'
export default {
    components:{
        MovimentiMain
    }
}
</script>