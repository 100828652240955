import axios from 'axios'

export default {
    state: {
        movimenti: []
    },
    getters: {

    },
    mutations: {
        set_movimenti(state, movimenti) {
            state.movimenti = movimenti
        }
    },
    actions: {
        fetch_movimenti(context) {
            return new Promise((resolve, reject) => {
                let auth = context.getters.get_auth
                axios.get(context.getters.get_api_root + '/movimenti', {'auth': auth})
                    .then(res => {
                        context.commit("set_movimenti", res.data)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })

        }
    }
}