export default {
    state: {
        //api_root: 'http://192.168.1.16:5000/api',
        //api_root: 'https://traceability.gmflor.com/api',
        //api_root: 'http://traceability.gmflor.com:5000/api',
        api_root:'/api',
        version: '0.2.0'
    },
    getters: {
        get_api_root: state => {
            return state.api_root
        },
        get_version: state =>{
            return state.version
        }
    }
}