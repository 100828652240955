import store from '../store/index'

export default {
    fetch_all() {
        console.log('fetching all ...')
        store.dispatch("fetch_prodotti");
        store.dispatch("fetch_fornitori");
        store.dispatch("fetch_torbe");
        store.dispatch("fetch_lotti");
        store.dispatch("fetch_movimenti");
        store.dispatch("request_clienti");
    }
}