import axios from 'axios'

export default {
    state: {
        fornitori: []
    },
    getters: {
        get_fornitori: state => {
            return state.fornitori
        },
        get_fornitore_by_cod: (state) => (cod) =>{
            return state.fornitori.find(fornitore =>{
                return fornitore.codice == cod
            })
        }
    },
    mutations: {
        set_fornitori(state, fornitori) {
            state.fornitori = fornitori
        }
    },
    actions: {
        fetch_fornitori(context) {
            return new Promise((resolve, reject) => {
                let auth = context.getters.get_auth
                axios.get(context.getters.get_api_root + '/fornitori', {'auth': auth})
                    .then(res => {
                        context.commit("set_fornitori", res.data)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })

        },
        add_fornitore(context, payload) {
            return new Promise((resolve, reject) => {
                let json = {
                    'nome': payload.nome,
                    'codice': payload.cod
                }
                let auth = context.getters.get_auth
                axios.post(context.getters.get_api_root + '/fornitore', json, {'auth': auth})
                    .then(() => {
                        //context.dispatch("fetch_fornitori")
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        edit_fornitore(context,payload){
            return new Promise((resolve, reject) => {
                let json = {
                    'nome': payload.nome,
                    'codice': payload.cod
                }
                let auth = context.getters.get_auth
                axios.put(context.getters.get_api_root + '/fornitore/' + payload.id, json, {'auth': auth})
                    .then((res) => {
                        //context.dispatch("fetch_fornitori")
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        delete_fornitore(context, codice){
            return new Promise((resolve, reject)=>{
                let auth = context.getters.get_auth
                axios.delete(context.getters.get_api_root + "/fornitore/" + codice, {'auth': auth})
                .then(()=>{
                    context.dispatch("fetch_fornitori")
                    resolve('ok')
                })
                .catch(err=>{
                    console.log(err.response.status)
                    if(err.response.status == 400){
                        resolve('bound')
                    }
                    reject(err)
                })
            })
        }
    }
}