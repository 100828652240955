<template>
    <StampaMain/>
</template>

<script>
import StampaMain from '@/components/stampa/stampa_main'
export default {
    components:{
        StampaMain
    }
    
}
</script>