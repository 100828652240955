<template>
<v-dialog width="600" v-model="dialog">
    <template v-slot:activator="{on}">
        <v-btn icon v-on="on">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </template>
    <v-card>
        <v-card-title>
            Nuovo Torba
        </v-card-title>
        <v-card-text>
            <v-form v-model="valid" ref="form">
                <v-row justify="center" class="mt-4">
                    <v-col cols="10">
                        <v-autocomplete outlined prepend-inner-icon="mdi-account" label="Fornitore" :items="fornitori" item-text="nome" item-value="codice" v-model="cod_fornit">
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-avatar>
                                        <img :src="data.item.logo_src">
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="10">
                        <v-text-field outlined label="Nome" v-model="nome" :rules="rule"></v-text-field>
                    </v-col>
                    <v-col cols="10">
                        <v-text-field outlined label="Codice" v-model="cod" :rules="rule"></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn text @click="dialog = false">
                Annulla
            </v-btn>
            <v-btn @click="submit()" :loading="loading">
                Invia
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        prodotto: Object
    },
    data() {
        return {
            dialog: false,
            valid: true,
            rule: [v => !!v || 'Non può essere vuoto'],
            loading: false,
            nome: '',
            cod: '',
            cod_fornit: ''
        }
    },
    computed:{
        ...mapGetters({
            fornitori: 'get_fornitori'
        }),
    },
    methods: {
        submit() {
            this.$refs.form.validate()
            if (this.valid) {
                this.loading = true
                this.$store.dispatch("add_torba", {
                        'nome': this.nome,
                        'cod': this.cod,
                        'fornitore': this.cod_fornit
                    })
                    .then(() => {
                        this.dialog = false
                    })
                    .catch(err => {
                        console.log(err)
                        alert('Errore: ' + err.status)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
}
</script>
