import axios from 'axios'

export default {
    state: {
        prodotti: []
    },
    getters: {
        get_prodotti: state => {
            return state.prodotti
        },
        get_prodotto_by_cod: state => cod =>{
            return state.prodotti.find(prodotto =>{
                return prodotto.cod == cod
            })
        }
    },
    mutations: {
        set_prodotti(state, prodotti) {
            state.prodotti = prodotti
        }
    },
    actions: {
        sync_prodotti(context) {
            return new Promise((resolve, reject) => {
                let auth = context.getters.get_auth
                let token = context.getters.get_token
                axios.post(context.getters.get_api_root + '/sincronizza_prodotti', { 'token': token }, { 'auth': auth })
                    .then(() => {
                        context.dispatch("fetch_prodotti")
                        resolve()
                    })
                    .catch(err => {
                        reject()
                        alert("Errore nella sincronizzazione." + err)
                    })

            })
        },
        fetch_prodotti(context) {
            return new Promise((resolve, reject) => {
                let auth = context.getters.get_auth
                axios.get(context.getters.get_api_root + '/prodotti', { 'auth': auth })
                    .then(res => {
                        context.commit("set_prodotti", res.data)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })

        },
        add_varietà(context, payload) {
            return new Promise((resolve, reject) => {
                let auth = context.getters.get_auth
                let json = {
                    nome: payload.nome,
                    colore: payload.colore,
                    cod_fornitore: payload.cod_fornitore,
                    avatar_src: payload.avatar_src
                }
                axios.post(context.getters.get_api_root + '/varieta/' + payload.cod_prod, json, { 'auth': auth })
                    .then(() => {
                        context.dispatch("fetch_prodotti")
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        del_varieta(context, payload) {
            return new Promise((resolve, reject) => {
                let auth = context.getters.get_auth
                axios.delete(context.getters.get_api_root + '/varieta/' + payload.prodotto + '/' + payload.cod_fornitore_origin + '/' + payload.varieta, { 'auth': auth })
                    .then(() => {
                        context.dispatch("fetch_prodotti")
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        copy_varieta(context, payload) {
            return new Promise((resolve, reject) => {
                let auth = context.getters.get_auth
                let json = {
                    'from': payload.from
                }
                axios.put(context.getters.get_api_root + '/varieta/' + payload.to, json, { 'auth': auth })
                    .then(() => {
                        context.dispatch("fetch_prodotti")
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        edit_varietà(context, payload) {
            return new Promise((resolve, reject) => {
                let auth = context.getters.get_auth
                let json = {
                    'nome': payload.nome,
                    'colore': payload.colore,
                    'cod_fornitore': payload.cod_fornitore,
                    'cod_variet': payload.id_variet,
                    'avatar_src': payload.avatar_src
                }
                let url = context.getters.get_api_root + '/varieta/' + payload.cod_fornitore_origin + '/' + payload.cod_prod
                axios.patch(url, json, { 'auth': auth })
                    .then(() => {
                        context.dispatch("fetch_prodotti")
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }
}