<template>
<v-row justify="center">
    <v-col cols="12" md="4">
        <v-card>
            <v-card-title class="primary--text">
                LogIn
            </v-card-title>
            <v-card-text>
                <v-text-field prepend-inner-icon="mdi-account" v-model="user" outlined label="Username"></v-text-field>
                <v-text-field prepend-inner-icon="mdi-lock" v-model="pass" outlined label="Password"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="pimary" :loading="loading" @click="login()">
                    LogIn
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
import fetch_all from '../../service/fetch_all'
export default {
    data() {
        return {
            loading: false,
            user: '',
            pass: ''
        }
    },
    methods: {
        login() {
            this.loading = true
            this.$store.dispatch("request_token", {
                    'username': this.user,
                    'password': this.pass
                })
                .then(() => {
                    fetch_all.fetch_all()
                    this.$router.push("/")
                })
                .catch(err => {
                    console.log(err)
                })  
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>
