import axios from 'axios'

export default {
    state: {
        torbe: []
    },
    getters: {
        get_torbe: state => {
            return state.torbe
        },
        get_torba_by_cod: state => cod =>{
            return state.torbe.find(torba =>{
                return torba.cod == cod
            })
        }
    },
    mutations: {
        set_torbe(state, torbe) {
            state.torbe = torbe
        }
    },
    actions: {
        fetch_torbe(context) {
            return new Promise((resolve, reject) => {
                let auth = context.getters.get_auth
                axios.get(context.getters.get_api_root + '/torba', {'auth': auth})
                    .then(res => {
                        context.commit("set_torbe", res.data)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })

        },
        add_torba(context, payload) {
            return new Promise((resolve, reject) => {
                let json = {
                    'nome': payload.nome,
                    'cod': payload.cod,
                    'fornitore': payload.fornitore
                }
                let auth = context.getters.get_auth
                axios.post(context.getters.get_api_root + '/torba', json, {'auth': auth})
                    .then(() => {
                        context.dispatch("fetch_torbe")
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        delete_torba(context, codice){
            return new Promise((resolve, reject)=>{
                let auth = context.getters.get_auth
                axios.delete(context.getters.get_api_root + "/torba/" + codice, {'auth': auth})
                .then(()=>{
                    context.dispatch("fetch_torbe")
                    resolve('ok')
                })
                .catch(err=>{
                    console.log(err.response.status)
                    if(err.response.status == 400){
                        resolve('bound')
                    }
                    reject(err)
                })
            })
        }
    }
}