<template>
<v-row justify="center">
    <v-col cols="10">
        <v-card>
            <v-card-title>
                Prodotti
                <v-spacer />
                <v-btn :loading="loading" icon @click="sincronizza()">
                    <v-icon>mdi-reload</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <v-text-field outlined hide-details v-model="search_text" placeholder="Cerca ..." class="mx-2 mb-4"></v-text-field>
                    </v-col>
                </v-row>
                <v-divider class="my-4" />
                <template v-for="prodotto in filtered">
                    <v-lazy :options="{threshold: .5}" min-height="50" transition="fade-transition" :key="prodotto.cod">
                        <ProdottoElement :prodotto="prodotto"></ProdottoElement>
                    </v-lazy>
                </template>
            </v-card-text>
        </v-card>
    </v-col>

</v-row>
</template>

<script>
import ProdottoElement from './prodotto_element'
import {
    mapGetters
} from 'vuex'
export default {
    components: {
        ProdottoElement
    },
    data() {
        return {
            search_text: '',
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            prodotti: 'get_prodotti'
        }),
        filtered() {
            if (this.search_text.length > 2) {
                let regex = new RegExp(this.search_text, 'i')
                return this.prodotti.filter(prodotto => {
                    return regex.test(prodotto.nome)
                }).sort((a, b) => {
                    return a - b
                })
            } else {
                return this.prodotti
            }
        }
    },
    methods: {
        sincronizza() {
            this.loading = true
            this.$store.dispatch("sync_prodotti")
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>
