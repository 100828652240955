<template>
<v-row justify="center">
    <v-col cols="8">
        <v-card>
            <v-card-title>
                Lotti
                <v-spacer />
                <NuovoLotto />
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <v-text-field v-model="search_text" outlined placeholder="Cerca Lotto ..."></v-text-field>

                    </v-col>
                </v-row>
                <v-row class="mb-2">
                    <v-col cols="1">
                        
                    </v-col>
                    <v-col cols="1">
                        Codice
                    </v-col>
                    <v-col cols="2">
                        Data
                    </v-col>
                    <v-col cols="1">
                        Settimana
                    </v-col>
                    <v-col cols="4">
                        Fornitore
                    </v-col>
                    <v-col cols="2">
                        Documento
                    </v-col>
                </v-row>
                <v-divider class="mb-4" />
                    <template v-for="lotto in filtered">
                        <LottoElement :lotto="lotto" :key="lotto.cod" />
                    </template>
            </v-card-text>
        </v-card>
    </v-col>

</v-row>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import LottoElement from './lotto_element'
import NuovoLotto from './nuovo_lotto'
export default {
    components: {
        LottoElement,
        NuovoLotto
    },
    data() {
        return {
            search_text: ''
        }
    },
    computed: {
        ...mapGetters({
            lotti: 'get_lotti'
        }),
        filtered() {
            if (this.search_text.length > 2) {
                let regex = new RegExp(this.search_text, 'i')
                return this.lotti.filter(lotto => {
                    return regex.test(lotto.codice)
                }).sort((a, b) => {
                    return a - b
                })
            } else {
                return this.lotti
            }
        }
    }

}
</script>
