<template>
    <LoginMain/>
</template>

<script>
import LoginMain from '@/components/login/login_main'
export default {
    components:{
        LoginMain
    }
    
}
</script>