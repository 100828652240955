<template>
<v-row class="mx-2" align="center">
    <v-col cols="1">
        <v-avatar>
            <v-img :src="prodotto.avatar_src" />
        </v-avatar>
    </v-col>
    <v-col cols="1">
        {{prodotto.cod}}
    </v-col>

    <v-col cols="4">
        {{prodotto.nome}}
    </v-col>
    <v-col cols="1">
        {{prodotto.diamentro}}
    </v-col>
    <v-col cols="4">
        {{prodotto.nome_sc}}
    </v-col>
    <v-col cols="1">
        <PannelloVarieta :prodotto="prodotto"/>
    </v-col>
</v-row>
</template>

<script>
import PannelloVarieta from './pannello_varietà'
export default {
    components:{
        PannelloVarieta
    },
    props: {
        prodotto: Object
    }

}
</script>
