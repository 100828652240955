import axios from 'axios'

export default {
    state: {
        lotti: []
    },
    getters: {
        get_lotti: state => {
            return state.lotti
        },
        get_lotto_by_cod: state => cod =>{
            return state.lotti.find(lotto =>{
                return lotto.codice == cod
            })
        }
    },
    mutations: {
        set_lotti(state, lotti) {
            state.lotti = lotti
        }
    },
    actions: {
        fetch_lotti(context) {
            return new Promise((resolve, reject) => {
                let auth = context.getters.get_auth
                axios.get(context.getters.get_api_root + '/lotti',{'auth': auth})
                    .then(res => {
                        context.commit("set_lotti", res.data)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })

        },
        add_lotto(context, payload) {
            return new Promise((resolve, reject) => {
                let url = context.getters.get_api_root + '/lotto'
                let auth = context.getters.get_auth
                    axios.post(url, payload, {'auth': auth})
                        .then(() => {
                            context.dispatch("fetch_lotti")
                            resolve()
                        })
                        .catch(err => {
                            reject(err)
                        })
            })
        },
        delete_lotto(context, codice){
            return new Promise((resolve, reject) => {
                let url = context.getters.get_api_root + '/lotto/' + codice
                let auth = context.getters.get_auth
                    axios.delete(url, {'auth': auth})
                        .then(() => {
                            context.dispatch("fetch_lotti")
                            resolve()
                        })
                        .catch(err => {
                            reject(err)
                        })
            })
        }
    }
}