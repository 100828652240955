<template>
<v-row :key="varieta.nome" align="center">
    <v-col cols="1">
        <v-avatar size="50" color="" class="white--text">
            <v-img :src="varieta.avatar_src"/>
        </v-avatar>
    </v-col>
    <v-col cols="3">
        {{varieta.nome}}
    </v-col>
    <v-col cols="3">
        {{varieta.colore}}
    </v-col>
    <v-col cols="2">
        {{produttore}}
    </v-col>
    <v-col cols="1">
        <v-btn icon @click="elimina">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
    </v-col>
    <v-col cols="1">
        <EditVar :prodotto="prodotto" :varieta="varieta" />
    </v-col>
</v-row>
</template>

<script>
import EditVar from './edit_varietà'
export default {
    components: {
        EditVar
    },
    props: {
        varieta: Object,
        prodotto: Object
    },
    computed: {
        avatar() {
            let prod = this.$store.getters.get_fornitore_by_cod(this.varieta.cod_fornitore)
            if(prod){
               return prod.logo_src 
            }else{
                return ''
            }
            
             
        },
        produttore() {
            let prod = this.$store.getters.get_fornitore_by_cod(this.varieta.cod_fornitore)
            if (prod){
               return prod.nome 
            }else{
                return ''
            }
            
        }
    },
    methods: {
        elimina() {
            this.$store.dispatch("del_varieta", {
                'prodotto': this.prodotto.id,
                'varieta': this.varieta.nome,
                'cod_fornitore_origin': this.varieta.cod_fornitore
            })
        }
    }
}
</script>
