<template>
<v-dialog width="800" v-model="dialog">
    <template v-slot:activator="{on}">
        <v-btn icon v-on="on">
            <v-icon>mdi-pencil</v-icon>
        </v-btn>
    </template>
    <v-card>
        <v-card-title>
            Modifica Varietà
        </v-card-title>
        <v-card-text>
            <v-form v-model="valid" ref="form">
                <v-row justify="center" class="mt-4">
                    <v-col cols="12">
                        <v-autocomplete outlined prepend-inner-icon="mdi-account" label="Fornitore" :items="fornitori" item-text="nome" item-value="codice" v-model="cod_fornit">
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-avatar>
                                        <img :src="data.item.logo_src">
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete outlined prepend-inner-icon="mdi-palette" label="Avatar" :items="avatar_list" item-text="nome" item-value="url" v-model="src_colore" @click="fetch()">
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-avatar>
                                        <v-img :src="data.item.url" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field outlined label="Nome" v-model="nome" :rules="rule" ref="nome_box"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field outlined label="Colore" v-model="colore" :rules="rule" @keypress.enter="submit()"></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn text @click="dialog = false">
                Annulla
            </v-btn>
            <v-btn @click="submit()" :loading="loading">
                Invia
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import axios from 'axios'
export default {
    props: {
        prodotto: Object,
        varieta: Object
    },
    data() {
        return {
            dialog: false,
            valid: true,
            rule: [v => !!v || 'Non può essere vuoto'],
            loading: false,
            nome: this.varieta.nome,
            colore: this.varieta.colore,
            cod_fornit: this.varieta.cod_fornitore,
            avatar_list: [],
            src_colore: ''
        }
    },
    computed: {
        ...mapGetters({
            fornitori: 'get_fornitori'
        }),
    },
    methods: {
        fetch() {
            let url = 'https://listino.gmflor.com/api/admin/prodotto/' + this.prodotto.cod
            let auth = this.$store.getters.get_auth
            axios.get(url, {
                    'auth': auth
                })
                .then(res => {

                    this.avatar_list = res.data.colori.map(elem => {
                        return {
                            'nome': elem.nome,
                            'url': elem.url
                        }
                    })
                    this.avatar_list.push({
                        'nome': 'Non Specificato',
                        'url': null
                    })
                    console.log(this.avatar_list)
                })
        },
        submit() {
            this.$refs.form.validate()
            if (this.valid) {
                this.loading = true
                this.$store.dispatch("edit_varietà", {
                        'id_variet': this.varieta.nome,
                        'cod_prod': this.prodotto.id,
                        'nome': this.nome,
                        'colore': this.colore,
                        'cod_fornitore': this.cod_fornit,
                        'cod_fornitore_origin': this.varieta.cod_fornitore,
                        'avatar_src': this.src_colore
                    })
                    .then(() => {
                        this.$refs.form.reset()
                        this.$refs.nome_box.focus()
                    })
                    .catch(err => {
                        alert('Errore: ' + err)
                    })
                    .finally(() => {
                        this.loading = false
                        this.dialog = false
                    })
            }
        }
    }
}
</script>
