<template>
    <TorbeMain/>
</template>

<script>
import TorbeMain from '@/components/torbe/torbe_main'
export default {
    components:{
        TorbeMain
    }
}
</script>