import axios from 'axios'

export default {
    state: {
        clienti: []
    },
    getters: {
        get_clienti: state => {
            return state.clienti
        },
        get_cliente_by_iva: state => iva => {
            return state.clienti.find(cliente =>{
                return cliente.iva == iva
            })
        }
    },
    mutations: {
        set_clienti(state, clienti) {
            state.clienti = clienti
        }
    },
    actions: {
        request_clienti(context) {
            return new Promise((resolve, reject) => {
                let auth = context.getters.get_auth
                console.log(auth)
                axios.get("https://listino.gmflor.com/api/admin/clienti", { 'auth': auth })
                    .then(res => {
                        context.commit("set_clienti", res.data)
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }

}