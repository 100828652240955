<template>
<v-dialog v-model="dialog" width="1000">
    <template v-slot:activator="{on}">
        <v-btn icon v-on="on">
            <v-icon>mdi-pencil</v-icon>
        </v-btn>
    </template>
    <v-card>
        <v-card-title>
            Varietà
            <v-spacer />
            <NuovaVar :prodotto="prodotto" />
            <CopyVar :prodotto="prodotto" />
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="1">

                </v-col>
                <v-col cols="3">
                    Nome
                </v-col>
                <v-col cols="3">
                    Colore
                </v-col>
                <v-col cols="2">
                    Produttore
                </v-col>
            </v-row>

            <template v-for="(list, nome, index) in grouped_list">
                <v-divider :key="index" class="" />
                <v-row justify="center" align="center" class="green--text" no-gutters :key="nome">
                    <v-col cols="1">
                        <v-avatar size="50">
                            <v-img :src="logo_produttore(nome)" />
                        </v-avatar>
                    </v-col>
                    <v-col cols="11">
                        <span class="text-h5" style="cursor: pointer" @click="shows = true">{{nome_produttore(nome)}}</span>
                    </v-col>

                </v-row>
                    <v-divider :key="index" class="mb-2" />
                <template v-for=" varieta in list">
                    <VarietaElement :varieta="varieta" :prodotto="prodotto" :key="varieta.nome" />
                </template>

            </template>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn @click="dialog=false" text>Annulla</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import _ from 'lodash'
import NuovaVar from './aggiungi_varietà'
import CopyVar from './copia_varieta'
import VarietaElement from './varieta_element'
export default {
    components: {
        NuovaVar,
        CopyVar,
        VarietaElement
    },
    props: {
        prodotto: Object
    },
    data() {
        return {
            dialog: false,
            show_i: [],
        }
    },
    mounted() {
        console.log()
    },
    computed: {
        sorted_var() {
            return this.prodotto.varieties.slice().sort((a, b) => {
                if (a.nome < b.nome) {
                    return -1
                }
                if (a.nome > b.nome) {
                    return 1
                }
            })
        },
        grouped_list() {
            return _.groupBy(this.sorted_var, el => {
                return el.cod_fornitore
            })
        }
    },
    methods: {
        nome_produttore(cod) {
            let prod = this.$store.getters.get_fornitore_by_cod(cod)
            if(prod){
                return prod.nome
            }else{
              return   'Nessuno'
            }
            
        },
        logo_produttore(cod){
            let prod = this.$store.getters.get_fornitore_by_cod(cod)
            if(prod){
                return prod.logo_src
            }else{
                return ''
            }
        },
        elimina(nome) {
            this.$store.dispatch("del_varieta", {
                'prodotto': this.prodotto.cod,
                'varieta': nome
            })
        }
    }
}
</script>
