<template>
<v-dialog width="400" v-model="dialog">
    <template v-slot:activator="{on}">
        <v-btn icon v-on="on">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </template>
    <v-card>
        <v-card-title>
            Nuova lotto
        </v-card-title>
        <v-card-text>
            <v-row justify="center" class="mt-4">
                <v-form v-model="valid" ref="form">
                    <v-col cols="12">
                        <v-autocomplete outlined prepend-inner-icon="mdi-account" label="Fornitore" :items="fornitori" item-text="nome" item-value="codice" v-model="cod_fornit">
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-avatar>
                                        <img :src="data.item.logo_src">
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-menu v-model="menu_data" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field :value="formatted_date" label="Data Documento" outlined prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="date" @input="menu_data = false" locale="it-it"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field outlined prepend-inner-icon="mdi-file-outline" label="N. Documento" v-model="documento"></v-text-field>

                    </v-col>
                </v-form>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn text @click="dialog = false">
                Annulla
            </v-btn>
            <v-btn @click="submit()" :loading="loading">
                Invia {{lotto_str}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import moment from 'moment'
export default {
    props: {
        prodotto: Object
    },
    data() {
        return {
            dialog: false,
            valid: true,
            rule: [v => !!v || 'Non può essere vuoto'],
            loading: false,
            menu_data: false,
            date: moment().format('YYYY-MM-DD'),
            documento: '',
            cod_fornit: null
        }
    },
    computed: {
        ...mapGetters({
            fornitori: 'get_fornitori'
        }),
        lotto_str() {
            return this.cod_fornit + moment(this.date).format('WWYY')
        },
        formatted_date() {
            moment.locale('it_IT')
            return moment(this.date).format('dddd DD MMMM')
        }
    },
    methods: {
        submit() {
            this.$refs.form.validate()
            if (this.valid) {
                this.loading = true
                this.$store.dispatch("add_lotto", {
                        'codice': this.lotto_str,
                        'data': moment(this.date).format("X"),
                        'cod_produttore': this.cod_fornit,
                        'documento': this.documento,
                        'produttore': this.$store.getters.get_fornitore_by_cod(this.cod_fornit).nome
                    })
                    .then(() => {
                        this.dialog = false
                    })
                    .catch(err => {
                        alert('Errore: ' + err)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
}
</script>
