<template>
    <ProdottiMain/>
</template>

<script>
import ProdottiMain from '@/components/prodotti/prodotti_main'
export default {
    components:{
        ProdottiMain
    }
    
}
</script>