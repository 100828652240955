<template>
<v-dialog width="600" v-model="dialog">
    <template v-slot:activator="{on}">
        <v-btn icon v-on="on">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </template>
    <v-card>
        <v-card-title>
            Nuovo Fornitore
        </v-card-title>
        <v-card-text>
            <v-form v-model="valid" ref="form">
                <v-row justify="center" class="mt-4">
                    <v-col cols="10">
                        <v-text-field outlined label="Nome" v-model="nome" :rules="rule"></v-text-field>

                    </v-col>
                    <v-col cols="10">
                        <v-text-field outlined label="Codice" v-model="cod" :rules="rule"></v-text-field>

                    </v-col>

                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6" class="d-flex justify-center">
                        <v-card class="portrait d-flex justify-center" :img="image_box" height="150" width="150" @click="open_file_window()">
                            <v-icon v-if="file==''">mdi-camera</v-icon>
                            <input type="file" style="display: none" @change="onFileChange($event)" ref="input" />
                        </v-card>
                    </v-col>
                </v-row>
                <v-dialog width="200" v-model="load_bar">
                    <v-progress-linear color="primary" :value="percentLoad" height="18">
                        <span class="white--text">{{percentLoad}}</span>
                    </v-progress-linear>
                </v-dialog>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn text @click="dialog = false">
                Annulla
            </v-btn>
            <v-btn @click="submit()" :loading="loading">
                Invia
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import axios from 'axios'
export default {
    props: {
        prodotto: Object
    },
    data() {
        return {
            dialog: false,
            valid: true,
            rule: [v => !!v || 'Non può essere vuoto'],
            loading: false,
            nome: '',
            cod: '',
            image_box: '',
            percentLoad: 0,
            load_bar: false,
            file:''
        }
    },
    methods: {
        open_file_window() {
            this.$refs.input.click()
        },
        onFileChange(e) {
            this.file = e.target.files[0]
            this.createImage(this.file);
        },
        createImage(file) {
            var reader = new FileReader();
            reader.addEventListener('load', () => {
                this.image_box = reader.result;
            }, false)
            reader.readAsDataURL(file);
        },
        submit() {
            this.$refs.form.validate()
            if (this.valid) {
                this.loading = true
                this.$store.dispatch("add_fornitore", {
                        'nome': this.nome,
                        'cod': this.cod,
                    })
                    .then(() => {
                        let formData = new FormData();
                        formData.append("file", this.file);
                        let url = this.$store.getters.get_api_root + "/fornitore/logo/" + this.cod
                        let auth = this.$store.getters.get_auth
                        axios
                            .post(url, formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data"
                                },
                                auth: auth,
                                onUploadProgress: (progressEvent) => {
                                    this.percentLoad = Math.round(
                                        (progressEvent.loaded * 100) / progressEvent.total
                                    );
                                },
                            })
                            .catch(err => {
                                console.log(err)
                            })
                            .finally(() => {
                                this.$store.dispatch("fetch_fornitori")
                                this.dialog = false
                            })

                    })
                    .catch(err => {
                        console.log(err)
                        alert('Errore: ' + err.status)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
}
</script>
