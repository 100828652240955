<template>
<v-row justify="center">
    <v-col cols="10">
        <v-card>
            <v-card-title>
                Imposta Stampa
            </v-card-title>
            <v-card-text>
                <v-row justify="center">
                    <v-col cols="4">
                        <v-autocomplete outlined prepend-inner-icon="mdi-account" label="Prodotto" :items="prodotti" item-text="nome" item-value="cod" v-model="cod_prodotto">
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-avatar>
                                        <v-img :src="data.item.avatar_src" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="4">
                        <v-autocomplete outlined prepend-inner-icon="mdi-account" label="Lotto" :items="lotti" item-text="codice" item-value="codice" v-model="cod_lotto">
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-avatar>
                                        <img :src="produttore(data.item.produttore).logo_src" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.codice"></v-list-item-title>
                                        <v-list-item-subtitle v-html="produttore(data.item.produttore).nome"></v-list-item-subtitle>
                                        <v-list-item-subtitle v-html="data.item.documento"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="4">
                        <v-autocomplete outlined prepend-inner-icon="mdi-account" label="Torba" :items="torbe" item-text="nome" item-value="cod" v-model="cod_torba">
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-avatar>
                                        <img :src="produttore(data.item.fornitore).logo_src" />
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                                        <v-list-item-subtitle v-html="produttore(data.item.fornitore).nome"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-divider/>
                <v-row justify="center" class="my-4">
                    <v-col cols="4">
                        <v-list-item>
                            <v-list-item-avatar size="70">
                                <v-img :src="prodotto_avatar"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{prodotto_nome}}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{prodotto_ns}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                    </v-col>
                    <v-col cols="4" class="d-flex justify-center">
                        <v-list-item>
                            <v-list-item-avatar size="70">
                                <v-img :src="lotto_avatar" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-capitalize" v-html="cod_lotto" />
                                <v-list-item-subtitle v-html="lotto_produt" />
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="4">
                        <v-list-item>
                            <v-list-item-avatar size="70">
                                <v-img :src="torba_avatar" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-html="torba_nome" />
                                <v-list-item-subtitle v-html="torba_cod" />
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>

                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="center">
                    <v-btn outlined color="green" :disabled="!btn_ok" :loading="loading" @click="send()">Invia</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="d_ok" width="400">
            <v-card>
                <v-card-title class="success--text">
                    Prodotto Impostato
                </v-card-title>
                <v-card-text>
                    Prodotto impostato correttamente
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="green" outlined @click="d_ok = false">
                        chiudi
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="d_err" width="400">
            <v-card>
                <v-card-title class="error--text">
                    Errore
                </v-card-title>
                <v-card-text>
                    Errore di connessione
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="green" outlined @click="d_ok = false">
                        chiudi
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-col>
</v-row>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import axios from 'axios'
export default {
    data() {
        return {
            cod_prodotto: null,
            cod_lotto: null,
            cod_torba: null,
            loading: false,
            d_ok: false,
            d_err: false
        }
    },
    computed: {
        ...mapGetters({
            torbe: 'get_torbe',
            prodotti: 'get_prodotti',
            lotti: 'get_lotti'
        }),

        prodotto_nome() {
            let prodotto = this.$store.getters.get_prodotto_by_cod(this.cod_prodotto)
            if (prodotto) {
                return prodotto.nome
            }
            return null
        },
        prodotto_avatar() {
            let prodotto = this.$store.getters.get_prodotto_by_cod(this.cod_prodotto)
            if (prodotto) {
                return prodotto.avatar_src
            }
            return null
        },
        prodotto_ns() {
            let prodotto = this.$store.getters.get_prodotto_by_cod(this.cod_prodotto)
            if (prodotto) {
                return prodotto.nome_sc
            }
            return null
        },
        lotto_avatar() {
            let lotto = this.$store.getters.get_lotto_by_cod(this.cod_lotto)
            if (lotto) {
                return this.$store.getters.get_fornitore_by_cod(lotto.produttore).logo_src
            } else {
                return null
            }
        },
        lotto_produt() {
            let lotto = this.$store.getters.get_lotto_by_cod(this.cod_lotto)
            if (lotto) {
                return this.$store.getters.get_fornitore_by_cod(lotto.produttore).nome
            } else {
                return null
            }
        },
        torba_nome() {
            let torba = this.$store.getters.get_torba_by_cod(this.cod_torba)
            if (torba) {
                return torba.nome
            } else {
                return null
            }
        },
        torba_cod() {
            let torba = this.$store.getters.get_torba_by_cod(this.cod_torba)
            if (torba) {
                return this.$store.getters.get_fornitore_by_cod(torba.fornitore).nome
            } else {
                return null
            }
        },
        torba_avatar() {
            let torba = this.$store.getters.get_torba_by_cod(this.cod_torba)
            if (torba) {
                return this.$store.getters.get_fornitore_by_cod(torba.fornitore).logo_src
            } else {
                return null
            }
        },
        btn_ok() {
            return this.cod_torba && this.cod_prodotto && this.cod_lotto
        }
    },
    methods: {
        produttore(cod) {
            return this.$store.getters.get_fornitore_by_cod(cod)
        },
        send() {
            let url = this.$store.getters.get_api_root + "/setPrint"
            let auth = this.$store.getters.get_auth
            this.loading = true
            axios.post(url, {
                    'cod_prodotto': this.cod_prodotto,
                    'cod_lotto': this.cod_lotto,
                    'cod_torba': this.cod_torba
                }, {
                    'auth': auth
                })
                .then(() => {
                    this.d_ok = true
                })
                .catch(() => {
                    this.d_err = true
                })
                .finally(() => {
                    this.loading = false
                })
        }

    }
}
</script>
