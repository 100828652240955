<template>
    <v-row justify="center" class="mt-8">
            <v-card>
                <v-card-title>
                    Inserisci
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="10">
                           <v-text-field v-model="nome" label="nome" outlined/> 
                        </v-col>
                        <v-col cols="10">
                           <v-text-field v-model="colore" label="colore" outlined/> 
                        </v-col>
                        <v-col cols="10">
                           <v-text-field v-model="prodotto" label="prodotto" outlined/> 
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="insert()">
                        Vai
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-row>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            nome: '',
            colore: '',
            prodotto: ''
        }
    },
    methods:{
        insert(){
            axios.delete('http://localhost:5000/api/lotto/ap21')
        }
    }
}
</script>