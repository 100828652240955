<template>
<v-row class="" align="center">
    <v-col cols="1">
        <v-avatar>
            <v-img :src="fornitore.logo_src"/>
        </v-avatar>
    </v-col>
    <v-col cols="1">
        {{torba.cod}}
    </v-col>
    
    <v-col cols="3">
        {{fornitore.nome}}
    </v-col>
    <v-col cols="4">
        {{torba.nome}}
    </v-col>
    <v-col cols="2">
        <v-dialog v-model="dialog" width="400">
            <template v-slot:activator="{on}">
                <v-btn icon v-on="on">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="red--text">
                    Sei sicuro ?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="grey" @click="dialog = false">
                        Annulla
                    </v-btn>
                    <v-btn outlined color="red" :loading="loading" @click="elimina">
                        Elimina
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-col>
</v-row>
</template>

<script>
export default {
    components: {},
    props: {
        torba: Object
    },
    data() {
        return {
            dialog: false,
            loading: false
        }
    },
    computed:{
        fornitore(){
            return this.$store.getters.get_fornitore_by_cod(this.torba.fornitore)
        }
    },
    methods:{
        elimina() {
            this.loading = true
            this.$store.dispatch("delete_torba", this.torba.id)
                .then(()=>{
                
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>