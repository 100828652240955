<template>
<v-app>
    <AppBar />
    <v-main>
        <v-container fluid>
            <router-view></router-view>
        </v-container>
    </v-main>
    <v-footer>
        <v-spacer />
        app version {{ version }}
    </v-footer>
</v-app>
</template>

<script>
import AppBar from "@/components/app_bar/app_bar.vue";
import axios from 'axios';
import fetch_all from './service/fetch_all'
export default {
    name: "App",

    components: {
        AppBar,
    },

    data: () => ({
        //
    }),
    mounted() {
        console.log("check token ...")
        let token = localStorage.getItem("token")
        if(token){
            console.log("token exist. check if expired ...")
            axios.get('https://listino.gmflor.com/api/token', {'auth': {'username': token, 'password': ''}})
            .then(()=>{
                console.log("token valid. fetching")
                this.$store.commit("set_token", token)
                fetch_all.fetch_all()
            })
            .catch(()=>{
                console.log("token expired. push to login")
                this.$router.push('/login')
            })
        }else{
            console.log("token don't exist. push to login")
            this.$router.push("/login")
        }
        // this.$store.dispatch("fetch_prodotti");
        // this.$store.dispatch("fetch_fornitori");
        // this.$store.dispatch("fetch_torbe");
        // this.$store.dispatch("fetch_lotti");
        // this.$store.dispatch("fetch_movimenti");
        // this.$store
        //     .dispatch("request_token", {
        //         username: "admin",
        //         password: ".Ne86An11."
        //     })
        //     .then(() => {
        //         this.$store.dispatch("request_clienti");
        //     });
    },
    computed:{
        version(){
            return this.$store.getters.get_version
        }
    }
};
</script>
