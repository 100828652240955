<template>
<v-list-item>
    <v-list-item-avatar>
        <v-avatar color="primary white--text">

        </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
        <v-list-item-title>
            {{cliente_nome(movimento.iva)}}
        </v-list-item-title>
        <v-list-item-subtitle v-for="(data, index) in movimento.data" :key="data">
            {{index +1}} - {{data_format(data)}}
        </v-list-item-subtitle>
    </v-list-item-content>
</v-list-item>
</template>

<script>
import moment from 'moment'
export default {
    props: {
        movimento: Object
    },
    methods:{
        data_format(ts){
            moment.locale("it_IT")
            return moment.unix(ts).format("dddd DD MMMM YY")
        },
        initial(nome){
            return nome.substring(0,1)
        },
        cliente_nome(iva){
            return this.$store.getters.get_cliente_by_iva(iva).nome
        }
    }
}
</script>
