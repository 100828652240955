<template>
<v-row align="center">
    <v-col cols="1">

    </v-col>
    <v-col cols="2">
        <v-avatar size="80">
            <v-img :src="fornitore.logo_src"/>
        </v-avatar>
    </v-col>
    <v-col cols="1">
        {{fornitore.codice}}
    </v-col>
    <v-col cols="5" class="text-subtitle-1">
        {{fornitore.nome}}
    </v-col>
    <v-col cols="2">
        <EditFornitore :fornitore="fornitore"/>
        <v-dialog v-model="dialog" width="400">
            <template v-slot:activator="{on}">
                <v-btn icon v-on="on">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="red--text">
                    Sei sicuro ?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="grey" @click="dialog = false">
                        Annulla
                    </v-btn>
                    <v-btn outlined color="red" :loading="loading" @click="elimina">
                        Elimina
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-col>
</v-row>
</template>

<script>
import EditFornitore from './edit_fornitore'
export default {
    components: {
        EditFornitore
    },
    props: {
        fornitore: Object
    },
    data() {
        return {
            dialog: false,
            loading: false
        }
    },
    methods: {
        elimina() {
            this.loading = true
            this.$store.dispatch("delete_fornitore", this.fornitore.id)
                .then(res => {
                    if (res == 'bound') {
                        alert("Produttore collegato a un lotto")
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>
