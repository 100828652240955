<template>
<v-row justify="center">
    <v-col cols="8">
        <v-card>
            <v-card-title>
                Fornitori
                <v-spacer />
                <NuovoFornitore />
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <v-text-field v-model="search_text" outlined placeholder="Cerca Fornitore ..."></v-text-field>

                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        
                    </v-col>
                    <v-col cols="1">
                        Codice
                    </v-col>
                    <v-col cols="4">
                        Nome
                    </v-col>
                </v-row>
                <template v-for="fornitore in filtered">
                    <FornitoreElement :fornitore="fornitore" :key="fornitore.codice" />
                </template>
            </v-card-text>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import FornitoreElement from './fornitore_element'
import NuovoFornitore from './nuovo_fornitore'
export default {
    components: {
        FornitoreElement,
        NuovoFornitore
    },
    data() {
        return {
            search_text: ''
        }
    },
    computed: {
        ...mapGetters({
            fornitori: 'get_fornitori'
        }),
        filtered() {
            if (this.search_text.length > 2) {
                let regex = new RegExp(this.search_text, 'i')
                return this.fornitori.filter(fornitore => {
                    return regex.test(fornitore.nome)
                }).sort((a, b) => {
                    return a - b
                })
            } else {
                return this.fornitori
            }
        }
    }

}
</script>
